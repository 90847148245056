import React from "react"

// Libraries
import styled from "styled-components"

// Utils
import breakpoint from "utils/breakpoints/"
import { colors } from "utils/variables/"

// Components
import Container from "components/container"

const StyledHero = styled.div`
	background-color: ${colors.purpleDark};
	color: ${colors.white};
	text-align: center;

  ${Container} {
    max-width: 1100px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-content: center;
    height: 290px;

  ${breakpoint.medium`
  		height: 320px;
  `}
}

  .filters {
    margin-top: 20px;
    font-size: 11px;

    ${breakpoint.medium`
      margin-top: 24px;
      font-size: 14px;
    `}
  }

  .filter {
    display: inline-block;
    padding: 8px;
    margin: 2px;
    border: 1px solid ${colors.white};
    border-radius: 23px;
    text-align: center;
    transition: all 0.6s;
    cursor: pointer;
    min-width: 80px;

  ${breakpoint.small`
    padding: 10px 16px;
    margin: 5px;
  `}

  ${breakpoint.medium`
    margin: 0 10px 20px;
    min-width: 90px;
  `}


    &:hover,
    &.active {
      background-color: ${colors.orange};
      border-color: ${colors.orange};
    }
  }
  
  h1 { 
    color: ${colors.white}; 
    padding-bottom: 0;

  ${breakpoint.medium`
    padding-bottom: 1rem;
  `}
  }

`
const Hero = props => {
  return (
      <StyledHero data-aos="fade-in">
        <Container>
        <h1>Videos</h1>
        <p>
          Browse our collection of videos and promos. 
        </p>
        <div className="filters">
          <button
            type="button"
            data-filter
            data-filter-type="videos"
            data-target="all"
            className="filter"
            onClick={() => props.filterFunction("videos", "all")}
          >
            All
          </button>
          <button
            type="button"
            data-filter
            data-filter-type="videos"
            data-target="educational"
            className="filter"
            onClick={() => props.filterFunction("videos", "educational")}
          >
            Educational
          </button>
         <button
            type="button"
            data-filter
            data-filter-type="videos"
            data-target="visby_answers"
            className="filter"
            onClick={() => props.filterFunction("videos", "visby_answers")}
          >
            Visby Answers
          </button>
          <button
            type="button"
            data-filter
            data-filter-type="videos"
            data-target="testimonial"
            className="filter"
            onClick={() => props.filterFunction("videos", "testimonial")}
          >
            Testimonials
          </button>
        </div>
      </Container>
    </StyledHero>
  )
}

export default Hero
